import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
import test1 from '../../static/img/work/radioDabanga/prep/test-1.jpg';
import gif1 from '../../static/img/work/radioDabanga/dabanga-1.gif';
import db1 from '../../static/img/work/radioDabanga/dabanga-3.png';
import db2 from '../../static/img/work/radioDabanga/dabanga-2.png';
import db3 from '../../static/img/work/radioDabanga/dabanga-4.png';
export const _frontmatter = {
  "key": 4,
  "title": "A Story About Lines",
  "client": "Radio Dabanga / Free Press Unlimited",
  "type": "audiovisual",
  "date": "2019",
  "description": "Radio Dabanga is a Sudanese radio station, now operating in exile from Amsterdam. This is information and connectno can be the difference between life and death, because it tells what is happening around them. Information is a lifeline was the starting point for making this trailer. Only with those lines you will get a picture of what is going on.",
  "image": "../../static/img/work/radioDabanga/daanga-7.png",
  "video": "https://player.vimeo.com/video/272906948",
  "alt": "film still",
  "runningTime": "8:54",
  "rating": 3
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery width={100} mdxType="Gallery">
    <img src={gif1} alt="piece of the film" />
    <img src={test1} alt="test with lines" />
    <img src={db1} alt="test with lines" />
    <img src={db3} alt="test with lines" />
    <img src={db2} alt="test with lines" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      